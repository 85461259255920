/* latin */
@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Indie Flower'), local('IndieFlower'),
    url(assets/css/fonts/IndieFlower.woff2) format('woff2'),
    url(assets/css/fonts/IndieFlower.ttf) format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/*@import url('https://fonts.googleapis.com/css?family=Indie+Flower');*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(76, 87, 95);
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: Calibri, sans-serif;
  user-select: none;
}

div.view[data-view-level='1'] {
  left: 0;
}
div.view[data-view-level='2'] {
  left: 100%;
}
div.view[data-view-level='3'] {
  left: 200%;
}

/* th, td
{
    padding: 5px 5px;
    text-align: left;
}
th, td, table
{
    border: 1px solid white;
} */

.selected-item {
  position: relative;
}
.selected-item::before {
  display: block;
  content: '';
  position: absolute;
  background-color: rgba(0, 176, 240, 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

div.modal-xxl {
  width: 1024px;
}

.toggle-opened,
.toggle-closed {
  cursor: pointer;
}
.toggle-opened:after {
  content: '\25B2';
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  margin-left: 8px;
}
.toggle-closed:after {
  content: '\25BC';
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
  margin-left: 8px;
}

@media print {
  body {
    width: 100%;
  }
}
